// @flow
/* global document */
import * as React from 'react';
import { useFela, } from 'react-fela';
import gql from 'graphql-tag';
import { useQuery, } from 'react-apollo';
import { IconClose, } from '@haaretz/htz-components';


import A11yDialog from '../A11yDialog/A11yDialog';

type RefType = { current: null | Object, };

type WrapperPropsType = {
  children: ChildrenArray<Node> | Node,
  anchorRef: RefType,
  parentRef: RefType,
  displayFomoToolTip: Boolean,
}

type PropsType = WrapperPropsType & {
  isVisible: boolean,
  onClose: () => undefined,
  onShow: () => undefined,
  displayFomoToolTip: false,
  contentMiscStyles: Object,
}

const GET_PALTFORM_QUERY = gql`
  query GetPlatform {
    platform @client
  }
`;

function closeButtonStyle({ theme, }) {
  return {
    position: 'absolute',
    top: '0',
    end: '0',
    paddingTop: '1rem',
    paddingInlineEnd: '1rem',
  };
}

function hasEnoughRightSideSpace(anchorBounds) {
  return (anchorBounds.x + anchorBounds.width / 2 + 130 < document.documentElement.clientWidth - 12);
}

export default function ActionDialog({ isVisible, anchorRef, parentRef, onClose, onShow, displayFomoToolTip, children, }: PropsType) {
  const dialogButtonRef = React.useRef(null);
  const { theme, css, } = useFela();
  const { data, } = useQuery(GET_PALTFORM_QUERY);


  React.useEffect(() => {
    isVisible && onShow && onShow();
  }, [ isVisible, onShow, ]);

  const platform = data?.platform;

  if (!platform || !isVisible) {
    return null;
  }

  const containerBaseStyle = {
    backgroundColor: theme.color('neutral', '-10', 0.97),
    color: theme.color('neutral', '-1'),
    paddingInlineStart: displayFomoToolTip ? '0' : '4.5rem',
    paddingInlineEnd: displayFomoToolTip ? '0' : '4.5rem',
    transform: 'none',
  };

  const Wrapper = platform === 'mobile' ? MobileDialogWrapper : DesktopDialogWrapper;

  return (
    <Wrapper
      anchorRef={anchorRef}
      parentRef={parentRef}
      displayFomoToolTip={displayFomoToolTip}
    >
      {({ appendTo, containerMiscStyles, }) => (
        <A11yDialog
          appendTo={appendTo}
          focusableEl={dialogButtonRef}
          isVisible
          onClose={onClose}
          overlayMiscStyles={{ display: 'none', }}
          containerMiscStyles={{
            ...containerBaseStyle,
            ...containerMiscStyles,
          }}
          render={({ handleClose, }) => (
            <>
              <button
                type="button"
                aria-label={theme.readingListActionsI18n.closeDialog}
                className={css(closeButtonStyle)}
                onClick={() => handleClose()}
              >
                <IconClose size={3} />
              </button>
              <div>
                {children}
              </div>
            </>
          )}
        />
      )}
    </Wrapper>
  );
}

function MobileDialogWrapper({ displayFomoToolTip, children, }: WrapperPropsType) {
  const { theme, } = useFela();

  return children({
    appendTo: 'modalsRoot',
    containerMiscStyles: {
      borderTop: [ 1, 2, 'solid', theme.color('readingList', 'primary'), ],
      width: '100%',
      paddingBottom: displayFomoToolTip ? '0' : '2rem',
      position: 'fixed',
      top: 'unset',
      bottom: '0',
      left: '0',
      textAlign: 'unset',
    },
  });
}

function DesktopDialogWrapper({
  anchorRef,
  parentRef,
  displayFomoToolTip,
  children,
}: WrapperPropsType) {
  const { css, theme, } = useFela();
  const wrapperRef = React.useRef(null);
  const [
    {
      wrapperDynamicStyle,
      arrowDynamicStyle,
    }, setDynamicStyles, ] = React.useState({
    wrapperDynamicStyle: {},
    arrowDynamicStyle: {},
  });

  React.useEffect(() => {
    const anchorEl = anchorRef.current;
    const parentEl = parentRef.current;
    if (anchorEl && parentEl) {
      const anchorBounds = anchorEl.getBoundingClientRect();
      const parentBounds = parentEl.getBoundingClientRect();
      const anchorOffsetLeft = anchorBounds.x - parentBounds.x;
      const anchorX = anchorBounds.width / 2 + anchorOffsetLeft;
      const left = Math.max(anchorX - 170, 12 - (anchorBounds.x - anchorOffsetLeft));
      const right = hasEnoughRightSideSpace(anchorBounds)
        ? null
        : parentBounds.x + parentBounds.width - document.documentElement.clientWidth + 12;

      const isOnTop = anchorBounds.y >= 150;

      // eslint-disable-next-line no-shadow
      const wrapperDynamicStyle = {
        ...(right == null ? { left, } : { right, }),
        [isOnTop ? 'bottom' : 'top']: 'calc(100% + 10px)',
        opacity: 1,
      };
      // eslint-disable-next-line no-shadow
      const arrowDynamicStyle = {
        ...(isOnTop ? {
          borderLeft: 'none',
          borderTop: 'none',
          top: '100%',
          backgroundColor: displayFomoToolTip ? theme.color('tldr', 'bg') : theme.color('neutral', '-10'),
        } : {
          borderRight: 'none',
          borderBottom: 'none',
          top: 0,
        }),
        left: right == null ? anchorX - left : null,
        right: right == null ? null : Math.max(parentBounds.width - anchorX - right - 12, -2),
      };

      setDynamicStyles({
        wrapperDynamicStyle,
        arrowDynamicStyle,
      });

      return () => {
        setDynamicStyles({
          wrapperDynamicStyle: {},
          arrowDynamicStyle: {},
        });
      };
    }
    return undefined;
  }, [ anchorRef, displayFomoToolTip, parentRef, theme, ]);

  const paddingBottom = displayFomoToolTip ? { paddingBottom: '0', } : {};

  return (
    <>
      <div
        ref={wrapperRef}
        className={css({
          position: 'absolute',
          zIndex: theme.getZIndex('above', 10),
          opacity: '0',
          transitionDuration: '0.2s',
          transitionProperty: 'opacity',
        })}
        style={wrapperDynamicStyle}
      >
        <i
          // arrow
          className={css({
            position: 'absolute',
            width: '12px',
            height: '12px',
            backgroundColor: theme.color('neutral', '-10'),
            zIndex: theme.getZIndex('modal', 10),
            transform: 'translate(-50%, -50%) rotate(45deg)',
            color: theme.color('readingList', 'primary'),
            border: '1px solid',
          })}
          style={arrowDynamicStyle}
        />
      </div>

      {children({
        appendTo: wrapperRef,
        containerMiscStyles: {
          border: [ 1, 2, 'solid', theme.color('readingList', 'primary'), ],
          width: '300px',
          paddingInlineStart: displayFomoToolTip ? '0' : '3rem',
          paddingInlineEnd: displayFomoToolTip ? '0' : '3rem',
          ...paddingBottom,
          position: 'relative',
          top: 'unset',
          left: 'unset',
          textAlign: 'unset',
        },
      })}
    </>
  );
}
