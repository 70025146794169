/* eslint-disable react/prop-types */
/* global document localStorage  */
import * as React from 'react';
import { useFela, } from 'react-fela';
import useMedia from '../../../hooks/useMedia';
import ActionDialog from '../ActionDialog';
import IconClose from '../../Icon/icons/IconClose';
import Button from '../../Button/Button';
import { useDisplayFomoToolTip, } from '../../FomoToolTip/DisplayFomoToolTip';
import EventTracker from '../../../utils/EventTracker';

function ReadingListAlertDialog({
  isVisible,
  anchorRef,
  parentRef,
  setAlert,
  cancel,
  inMobileMenu,
}) {
  const dialogButtonRef = React.useRef(null);
  const [ busyAlertType, setBusyAlertType, ] = React.useState(null);
  const { css, theme, } = useFela();

  const containerMiscStyles = inMobileMenu
    ? {
      borderTop: [ 1, 3, 'solid', theme.color('readingList', 'primary'), ],
      width: '100%',
      paddingBottom: '3rem',
      position: 'fixed',
      top: 'unset',
      bottom: '0',
      left: '0',
    }
    : {
      border: [ 1, 3, 'solid', theme.color('readingList', 'primary'), ],
      width: '300px',
      paddingInlineStart: '4.5rem',
      paddingInlineEnd: '4.5rem',
      position: 'relative',
      top: 'unset',
      left: 'unset',
    };


  const displayFomoToolTip = useDisplayFomoToolTip();
  const isMobile = useMedia({ query: { until: 's', }, });

  return (
    <ActionDialog
      isMobile={inMobileMenu}
      containerMiscStyles={containerMiscStyles}
      anchorRef={anchorRef}
      parentRef={parentRef}
      isVisible={isVisible}
      displayFomoToolTip={displayFomoToolTip}
    >
      <>
        <div className={css({
          paddingInlineStart: displayFomoToolTip ? (inMobileMenu ? '4.5rem' : '3rem') : '0',
          paddingInlineEnd: displayFomoToolTip ? (inMobileMenu ? '4.5rem' : '3rem') : '0',
        })}
        >
          <p
            className={css({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontFamily: theme.fontStacks[theme.framedFont],
              extend: [
                theme.type(0, { until: 'xl', }),
                theme.type(-1, { from: 'xl', }),
                theme.mq({ until: 's', }, {
                  maxWidth: '40rem',
                  marginInlineStart: 'auto',
                  marginInlineEnd: 'auto',
                }),
              ],
            })}
          >
            <span>
              {theme.readingListActionsI18n.saved}
            </span>
            <span>
              {theme.readingListActionsI18n.alertQuestion}
            </span>
          </p>
          <div
            className={css({
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
              extend: [
                theme.type(-1, { until: 'xl', }),
                theme.type(-2, { from: 'xl', }),
              ],
            })}
          >
            <Button
              ref={dialogButtonRef}
              isBusy={busyAlertType === 'day'}
              isDisabled={busyAlertType != null}
              onClick={async () => {
                setBusyAlertType('day');
                await setAlert('day');
                setBusyAlertType(null);
              }}
              variant={theme.readingListStyle.buttonVariant}
              boxModel={{ hp: 4, vp: 1, }}
              miscStyles={{
                marginInlineEnd: '3rem',
              }}
            >
              {theme.readingListActionsI18n.tomorrow}
            </Button>
            <Button
              isBusy={busyAlertType === 'week'}
              isDisabled={busyAlertType != null}
              onClick={async () => {
                setBusyAlertType('week');
                await setAlert('week');
                setBusyAlertType(null);
              }}
              variant={theme.readingListStyle.buttonVariant}
            >
              {theme.readingListActionsI18n.nextWeek}
            </Button>
          </div>
        </div>
        {displayFomoToolTip
          ? (
            <EventTracker>
              {({ biAction, biImpression, }) => {
                biImpression({
                  feature: 'Reading List Alert',
                  featureType: 'Marketing',
                  campaignName: 'fomo',
                  campaignDetails: 'Reading List',
                });
                return (
                  <div className={css({
                    paddingTop: '2rem',
                    marginTop: '4rem',
                    paddingInlineStart: '3rem',
                    paddingInlineEnd: '3rem',
                    textAlign: 'center',
                    backgroundColor: theme.color('tldr', 'bg'),
                    ...theme.type(-1),
                  })}
                  >
                    <div className={css({ fontWeight: '700', marginBottom: isMobile ? '2rem' : '1rem', })}>{theme.fomoToolTipI18n.text.readingList}</div>
                    <Button
                      onClick={() => biAction({
                        actionCode: 3,
                        feature: 'Reading List Alert',
                        featureType: 'Marketing',
                        campaignName: 'fomo',
                        campaignDetails: 'Reading List',
                      })
                      }
                      href={theme.fomoToolTipI18n.href.readingList}
                      variant="salesOpaque"
                      fontSize={-1}
                      miscStyles={{
                        paddingInlineStart: '3rem',
                        paddingInlineEnd: '3rem',
                        marginBottom: '2.5rem',
                        borderRadius: '50px',
                      }}
                    >
                      {theme.fomoToolTipI18n.text.button}
                    </Button>
                  </div>
                );
              }}
            </EventTracker>
          ) : null }
        <button
          type="button"
          aria-label={theme.readingListActionsI18n.closeDialog}
          className={css({
            position: 'absolute',
            top: '0',
            end: '0',
            paddingTop: '1rem',
            paddingInlineEnd: '1rem',
          })}
          onClick={cancel}
        >
          <IconClose size={3} />
        </button>
      </>

    </ActionDialog>
  );
}

export default ReadingListAlertDialog;
