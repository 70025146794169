// @flow
/* global navigator */

import React from 'react';
import useUserAgent from '../../../hooks/useUseragent';

// **********  Typings **************
// ----------------------------------
export type ShareData = {
  text?: string,
  title?: string,
  url?: string,
};

type ExtraProps ={
  [key: string]: any,
}

export type ShareComponentProps = ExtraProps & {
  /* data to share */
  sharedData: ShareData,
  /* method to call when user cancels share operation. throws AbortError exeption */
  cancelShare: (() => void),
  /* rejects the share request for any other reason than cancel. throws Error */
  rejectShare: ((err: Error) => void),
  /* approves the share happend and resolves the share promise */
  approveShare: (() => void),
  /* handler to execute on share-close */
  onClose: (() => void),
  /* handler to execute on share-show */
  onShow: (() => void),
};

type ControledShareComponentProps = {
  shareComponent: React.ElementType<ShareComponentProps>,

};

type ControledShareComponentState = {
  sharedData: ShareData,
  extraProps: ExtraProps,
  resolver: () => undefined;
  rejector: (error: Error) => undefined;
};

// *********** Noop operation ************
function noopShareFunction() {
  return Promise.reject(new Error('attempt to use "share" function before it initialized.'));
}

function NoopShareComponent(props: ControledShareComponentProps) {
  return null;
}

/**
 * ControledShareComponent is a component that is use internaly, to wrap the supplied ShareComponent, provided by developer.
 * The controled-component lets the 'share' function to activate it by exposing internal methods to the forwardRef object.
 */
const ControledShareComponent = React.forwardRef((props: ControledShareComponentProps, ref) => {
  const { shareComponent: ShareComponent, } = props;
  const [ state, setState, ] = React.useState<ControledShareComponentState>({
    sharedData: null,
    extraProps: null,
    resolver: () => null,
    rejector: () => null,
  });

  // Expose the setIsVisible to ref object.
  React.useImperativeHandle(ref, () => ({
    share: (sharedData: ShareData, extraProps: ExtraProps) => {
      const sharePromise = new Promise((resolver, rejector) => {
        setState({
          sharedData,
          extraProps,
          resolver,
          rejector,
        });
      });

      return sharePromise;
    },
  }));

  // method to pass to the custom-share component, for canceling sharing.
  const cancelShare = () => {
    state.rejector(new Error('Share canceled by user', { cause: { type: 'AbortError', }, }));
    setState(s => ({ ...s, sharedData: null, }));
  };

  // method to pass to the custom-share component, for rejecting sharing for any reason othe than 'cancel'
  const rejectShare = (err: Error) => {
    state.rejector(err);
    setState(s => ({ ...s, sharedData: null, }));
  };

  // method to pass to the custom-share component, for resolving the share
  const approveShare = () => {
    state.resolver();
    setState(s => ({ ...s, sharedData: null, }));
  };

  return (typeof state.sharedData !== 'undefined')
    ? <ShareComponent sharedData={state.sharedData} cancelShare={cancelShare} approveShare={approveShare} rejectShare={rejectShare} {...(state.extraProps || {})} />
    : null;
});


/**
 * useShare provides a method to share text and URL on social-media, email, etc'.
 * It intends to use the browsers native share functionality, if exists on users device (navigator.share).
 * If native share is not available on the browser, the share method will activate a custom-share component, provided by developer.
 *
 * The custom-component will accepts a 'sharedData' as a prop, and is responsible to implement the share funcionality.
 * The custom-component mimics the native behavior and throws errors same as native share method:
 * 1. {AbortError} - The user canceled the share operation or there are no share targets available.
 * 2. {DataError} - There was a problem starting the share target or transmitting the data.
 * 3. {TypeError} - The specified share data cannot be validated. (A URL is badly formatted)
 *
 * useShare accepts a custom-component as parameter, and returns array consists of share-method and controled-component.
 * the controled-component should be placed where-ever is needed, and it will be activated by the share-method.
 *
 * IMPORTANT NOTE: avoid calling the 'share' method with an arrow-function parameter. it will cause an infinite re-render.
 *
 * @param {React.ElementType} shareComponent custom-component which implement its own share logic
 * @returns {[(shareData: ShareData) => Promise<void>, React.ElementType]} array consists of share-method and controled-component
 */
export default function useShare(shareComponent: React.ElementType<ShareData>) {
  // holds references to the 'share' method and the controled share-component.
  const [ shareFunctionAndComponentArray, setShareFunctionAndComponentArray, ] = React.useState([
    noopShareFunction, // share method
    NoopShareComponent, // share-component
  ]);

  const { isMobileDevice, } = useUserAgent();

  // A forwarded-ref for the controled share-component, which enables to the share method to activate it.
  const shareComponentRef = React.useRef();

  // the function to call for sharing
  let shareFunc;
  // The component to display when 'navigator.share' is not supported by the browser
  let controledShareComponent = ({ sharedData: shareData, }: ShareComponentProps) => null;

  React.useEffect(() => {
    const hasNativeShare = navigator?.share && typeof navigator.share === 'function';
    const isMobile = isMobileDevice();

    if (hasNativeShare && isMobile) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setShareFunctionAndComponentArray([ navigator.share, NoopShareComponent, ]);
    }
    else {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      shareFunc = (shareData: ShareData, extraProps: ExtraProps = null) => {
        const sharePromise = shareComponentRef.current.share(shareData, extraProps);
        return sharePromise;
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      controledShareComponent = (props: ShareComponentProps) => (
        <ControledShareComponent shareComponent={shareComponent || noopShareFunction} {...props} ref={shareComponentRef} />
      );

      setShareFunctionAndComponentArray([ shareFunc, controledShareComponent, ]);
    }
  }, [ shareComponent, ]);

  return shareFunctionAndComponentArray;
}
