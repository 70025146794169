/* eslint-disable react/prop-types */
/* global document localStorage  */
import * as React from 'react';
import { useFela, } from 'react-fela';
import Button from '../../Button/Button';
import A11yDialog from '../../A11yDialog/A11yDialog';
import Checkbox from '../../CheckBox/CheckBox';

import { writeToLocalStorage, } from '../../../utils/writeToStorage';
import { ADD_WITH_FORCE_KEY, } from './consts';

function ReadingListCappedDialog({
  isVisible,
  cancel,
  approve,
  count,
}) {
  const { css, theme, } = useFela();
  const dialogButtonRef = React.useRef(null);

  return (
    <A11yDialog
      styledModal
      closeOnOutsideClick
      appendTo="modalRoot"
      focusableEl={dialogButtonRef}
      isVisible={isVisible}
      containerMiscStyles={{
        backgroundColor: theme.color('neutral', '-10'),
        width: 'calc(100% - 5rem)',
        maxWidth: '52rem',
        padding: '2rem 5rem',
        fontFamily: theme.fontStacks[theme.framedFont],
      }}
      render={() => (
        <>
          <p>
            {theme.readingListActionsI18n.capped(count)}
          </p>
          <Checkbox
            label={theme.readingListActionsI18n.cappedCheckbox}
            variant={theme.readingListStyle.checkBoxVariant}
            miscStyles={{
              marginTop: '2rem',
              marginBottom: '2rem',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
            }}
            labelStyle={{
              paddingTop: '2px',
              type: -3,
            }}
            onChange={evt => {
              writeToLocalStorage({ key: ADD_WITH_FORCE_KEY, value: evt.target.checked, });
            }}
          />
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              extend: [ theme.type(1), ],
            })}
          >
            <Button
              ref={dialogButtonRef}
              isFlat
              boxModel={{ hp: 6, vp: 1, }}
              onClick={cancel}
              variant={theme.readingListStyle.buttonVariant}
            >
              {theme.readingListActionsI18n.cancel}
            </Button>
            <Button
              isFlat
              boxModel={{ hp: 6, vp: 1, }}
              onClick={approve}
              variant={theme.readingListStyle.buttonVariant}
            >
              {theme.readingListActionsI18n.ok}
            </Button>
          </div>
        </>
      )}
    />
  );
}


export default ReadingListCappedDialog;
