// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import ClickArea from '../ClickArea/ClickArea';

import type { ButtonProps, } from './types';

export default function Button({
  children,
  miscStyles,
  tabIndex,
  title,
  href,
  onClick,
  target,
  size,
  ...props
}: ButtonProps) {
  const { theme, } = useFela();
  return (
    <ClickArea
      size={size || 4}
      href={href}
      target={target}
      onClick={onClick}
      miscStyles={{
        fontWeight: '700',
        paddingInlineStart: '1rem',
        paddingInlineEnd: '1rem',
        type: -2,
        color: theme.color('primary'),
        ':hover': {
          color: theme.color('secondary'),
        },
        ...miscStyles,
      }}
      attrs={{
        title,
        tabIndex: tabIndex !== undefined ? tabIndex : '-1',
        ...props,
      }}
    >
      <VisuallyHidden>{title}</VisuallyHidden>
      {children}
    </ClickArea>
  );
}
