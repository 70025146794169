// @flow
/* global window document */
import * as React from 'react';
import { useFela, } from 'react-fela';
import { IconClose, } from '@haaretz/htz-components';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import ClickArea from '../ClickArea/ClickArea';

type DirectionType = 'up' | 'forward' | 'down' | 'backward';

type PropsType = {
  direction?: 'auto' | DirectionType,
  isVisible?: boolean,
  target: React.Ref<ElementType<any>>,
  offset?: number,
  onShow?: () => void,
  onHide?: () => void,
  frameMiscStyles?: StyleProps,
  contentMiscStyles?: StyleProps,
  closeButtonMiscStyles?: StyleProps,
  children: ChildrenArray<Node> | Node,
};

// CSS consts for positioning the bubble-tooltip pin
const directionVars = new Map([
  [ 'up', {
    start: 'calc(50% - var(--anchor-w) / 2)',
    top: 'calc(100% - var(--anchor-w) / 2)',
    rotate: '0deg',
  }, ],
  [ 'forward', {
    start: 'calc(0% - var(--anchor-w) / 2 - 1px)',
    top: 'calc(50% - var(--anchor-w) / 2)',
    rotate: '270deg',
  }, ],
  [ 'down', {
    start: 'calc(50% - var(--anchor-w) / 2)',
    top: 'calc(0% - var(--anchor-w) / 2 - 1px)',
    rotate: '180deg',
  }, ],
  [ 'backward', {
    start: 'calc(100% - var(--anchor-w) / 2)',
    top: 'calc(50% - var(--anchor-w) / 2)',
    rotate: '90deg',
  }, ],
]);

function tooltipStyle({ theme, direction, bubbleAnchorPosition, bubbleTransition, frameMiscStyles, }) {
  return {
    '--start': directionVars.get(direction).start,
    '--top': directionVars.get(direction).top,
    '--rotate': directionVars.get(direction).rotate,
    '--anchor-w': '2rem',
    '--bubble-offset-x': '0px',
    position: 'absolute',
    ...bubbleAnchorPosition,
    zIndex: theme.getZIndex('above'),
    display: 'flex',
    transform: `translateX(calc(${bubbleTransition.x} + var(--bubble-offset-x))) translateY(${bubbleTransition.y})`,
    flexDirection: 'column',
    maxWidth: '45rem',
    borderWidth: '1px',
    xborderColor: theme.color('primary'),
    borderStyle: 'solid',
    backgroundColor: theme.color('bg', 'base'),
    padding: '2rem 3rem',
    ':after': {
      content: '""',
      backgroundColor: 'inherit',
      display: 'block',
      width: 'var(--anchor-w)',
      height: 'var(--anchor-w)',
      borderColor: 'inherit',
      borderStyle: 'inherit',
      borderTopWidth: '0',
      borderRightWidth: 'inherit',
      borderBottomWidth: 'inherit',
      borderLeftWidth: '0',
      borderTopLeftRadius: '100%',
      transformOrigin: '50% 50%',
      transform: 'rotate(calc(var(--rotate) + 45deg))',
      position: 'absolute',
      top: 'var(--top)',
      start: 'calc(var(--start) - max(var(--bubble-offset-x), -1 * var(--bubble-offset-x)))',
    },
    extend: [
      ...(frameMiscStyles ? parseStyleProps(frameMiscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function hideButtonStyle({ theme, closeButtonMiscStyles, }) {
  return {
    ...theme.type(-1),
    position: 'absolute',
    lineHeight: '1em',
    top: '1rem',
    left: '1rem',
    width: '1em',
    height: '1em',
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.color('neutral', '-1'),
    extend: [
      theme.type(-2, { fromBp: 's', }),
      ...(closeButtonMiscStyles ? parseStyleProps(closeButtonMiscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function tooltipContentStyle({ theme, contentMiscStyles, }) {
  return {
    display: 'block',
    color: theme.color('neutral', '-1'),
  };
}

BubbleTooltip.defaultProps = {
  direction: 'auto',
  isVisible: false,
  offset: null,
  onHide: null,
  onShow: null,
  frameMiscStyles: null,
  contentMiscStyles: null,
  closeButtonMiscStyles: null,
};

function getPageLogicDirection() {
  const { dir, lang, } = typeof document !== 'undefined'
    ? document.documentElement
    : { dir: 'rtl', lang: 'he', };

  return dir?.toLowerCase() === 'rtl' || lang?.toLowerCase() === 'he'
    ? 'rtl'
    : 'ltr';
}

// Test the visible space around the target element, and selects the direction of the bubble.
// The priority of direction is logic-direction (LTR or RTL): up -> after -> down -> before
function calculateBubbleDirection(target: HTMLElement): DirectionType {
  if (typeof target === 'undefined' || !(target instanceof HTMLElement)) {
    return 'up';
  }

  const [ bubbleWidth, bubbleHeight, ] = [ 276, 100, ];

  let direction = 'up';

  const pageDirection = getPageLogicDirection();

  const { top, right, left, bottom, } = target.getBoundingClientRect();
  const { innerWidth, innerHeight, } = window;

  // get logic position
  const [ start, end, ] = pageDirection === 'rtl'
    ? [ innerWidth - right, innerWidth - left, ]
    : [ left, right, ];

  // test space for up
  if (top > bubbleHeight) {
    direction = 'up';
  }
  else if (end > bubbleWidth && top > (bubbleHeight / 3)) {
    direction = 'forward';
  }
  else if ((innerHeight - bottom) > bubbleHeight) {
    direction = 'down';
  }
  else if (start > bubbleWidth && top > (bubbleHeight / 3)) {
    direction = 'backward';
  }

  return direction;
}

// calculates the position of the bubble pin, acording to direction of the buble and the target dimensions
function calculateAnchorCoordinates(direction: DirectionType, target: HTMLElement, offset?: number = 0): { top?: string, left?: string, } {
  if (typeof target === 'undefined' || !(target instanceof HTMLElement)) {
    return { top: '0', left: '0', };
  }

  const pageDirection = getPageLogicDirection() === 'ltr' ? 1 : -1;
  const { width, height, } = target.getBoundingClientRect();
  const { offsetLeft, offsetTop, } = target;

  const { start, top, } = pageDirection > 0
    ? { start: offsetLeft, top: offsetTop, }
    : { start: offsetLeft + width, top: offsetTop, };

  let cords;

  switch (direction) {
    case 'up':
      cords = { top: `${top + offset}px`, left: `${start + (pageDirection * width / 2)}px`, };
      break;
    case 'forward':
      cords = { top: `${top + (height / 2)}px`, left: `${start + (pageDirection * (width + offset))}px`, };
      break;
    case 'down':
      cords = { top: `${top + offset + height}px`, left: `${start + (pageDirection * width / 2)}px`, };
      break;
    case 'backward':
      cords = { top: `${top + (height / 2)}px`, left: `${start - (pageDirection * offset)}px`, };
      break;
    default:
      cords = { top: (top + offset), left: start, };
  }

  return cords;
}

// Calcultaes how the bubble should be translated in order position its anchor at point.
function calculateBubbleTransform(direction: DirectionType, target: HTMLElement): { x: string, y: string, } {
  const pageDirection = getPageLogicDirection() === 'ltr' ? 1 : -1;

  let transform;

  switch (direction) {
    case 'up':
      transform = { x: '-50%', y: 'calc(-1 * (100% + (var(--anchor-w) / 2)))', };
      break;
    case 'forward':
      transform = pageDirection > 0
        ? { x: 'calc(var(--anchor-w) / 2)', y: '-50%', }
        : { x: 'calc(-1 * (100% + var(--anchor-w) / 2))', y: '-50%', };
      break;
    case 'down':
      transform = { x: '-50%', y: 'calc(var(--anchor-w) / 2)', };
      break;
    case 'backward':
      transform = pageDirection > 0
        ? { x: 'calc(-1 * (100% + (var(--anchor-w) / 2)))', y: '-50%', }
        : { x: 'calc(var(--anchor-w) / 2)', y: '-50%', };
      break;
    default:
      transform = { x: '0%', y: '0%', };
  }

  return transform;
}

export default function BubbleTooltip({ children, target, offset, frameMiscStyles, contentMiscStyles, closeButtonMiscStyles, onShow, onHide, ...props }: PropsType) {
  const direction = props.direction === 'auto'
    ? calculateBubbleDirection(target.current)
    : props.direction;

  const bubbleAnchorPosition = React.useMemo(() => calculateAnchorCoordinates(direction, target.current, offset), [ direction, offset, target, ]);
  const bubbleTransition = React.useMemo(() => calculateBubbleTransform(direction, target.current), [ direction, target, ]);

  const { theme, css, } = useFela({ direction, bubbleAnchorPosition, bubbleTransition, frameMiscStyles, contentMiscStyles, closeButtonMiscStyles, });
  const [ isVisible, setIsVisible, ] = React.useState(props.isVisible);
  const bubbleRef = React.useRef();

  const onClose = React.useCallback(() => {
    onHide && onHide();
    setIsVisible(false);
  }, [ onHide, ]);

  React.useEffect(() => {
    if (isVisible) {
      onShow && onShow();

      const { left, width, } = bubbleRef.current.getBoundingClientRect();
      if (left < 0) {
        bubbleRef.current.style = `--bubble-offset-x: ${-1 * (left + 6)}px;`;
      } else if (left + width > window.screen.width) {
        bubbleRef.current.style = `--bubble-offset-x: ${window.screen.width - (left + width + 6)}px;`;
      }
    }
  }, [ isVisible, onShow, ]);

  if (!isVisible) {
    return null;
  }
  return (
    <div className={css(tooltipStyle)} ref={bubbleRef}>
      <ClickArea onClick={onClose} size={2} title={theme.readingListActionsI18n.closeDialog} className={css(hideButtonStyle)}>
        <IconClose size={2} />
      </ClickArea>

      <div className={css(tooltipContentStyle)}>
        {children}
      </div>
    </div>
  );
}
