/* global navigator */
import { useEffect, useRef, } from 'react';

function isAndroid(userAgent) {
  return () => userAgent.toLowerCase().match(/(android)/);
}

function isIPhone(userAgent) {
  return () => userAgent.toLowerCase().match(/(iphone)/);
}

function isMobileDevice(userAgent) {
  return () => (
    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent)
    || /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent));
}

export default function useUseragent() {
  const uaRef = useRef('');

  useEffect(() => {
    uaRef.current = navigator.userAgent || '';
  }, []);

  return {
    isMobileDevice: isMobileDevice(uaRef.current),
    isAndroid: isAndroid(uaRef.current),
    isIPhone: isIPhone(uaRef.current),
  };
}
