/* eslint-disable react/prop-types */
import * as React from 'react';
import { useQuery, } from 'react-apollo';
import GET_USER_BY_MAIL_BY_SITE from '../Account/queries/getUserByMailBySite.graphql';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import { useUser, } from '../User/UserDispenser';
import { getProductInfo, } from '../Account/helpers/subscription';
import useOneTime from '../../hooks/useOneTime';
import { useEventTracker, } from '../../utils/EventTracker';
import useWebViewChecker from '../../hooks/useWebViewChecker';

const { siteAlias, isHDC, } = checkSiteFromConfig();

function DisplayFomoToolTip({ impressionData, children, }) {
  const [ isDisplayFOMOToolTip, setDisplayFOMOToolTip, ] = React.useState(false);
  const { user, } = useUser();
  const { biImpression, } = useEventTracker();
  const isWebView = useWebViewChecker();

  const { data, } = useQuery(GET_USER_BY_MAIL_BY_SITE, {
    skip: !user || !user.email || isHDC || isWebView,
    variables: {
      email: user?.email,
      site: siteAlias?.toUpperCase(),
    },
  });
  const userWithProducts = data?.userByMail;

  React.useEffect(() => {
    if (userWithProducts && userWithProducts.products) {
      const product = getProductInfo(userWithProducts.products);
      setDisplayFOMOToolTip(product.subscriptionExpiresSoon);
    }
  }, [ userWithProducts, ]);

  useOneTime(isDisplayFOMOToolTip && !!impressionData, () => {
    biImpression(impressionData);
  });

  return (
    <>{isDisplayFOMOToolTip ? children : null}</>
  );
}

export function useDisplayFomoToolTip() {
  const [ isDisplayFOMOToolTip, setDisplayFOMOToolTip, ] = React.useState(false);
  const { user, } = useUser();
  const isWebView = useWebViewChecker();

  const { data, } = useQuery(GET_USER_BY_MAIL_BY_SITE, {
    skip: !user || !user.email || isHDC || isWebView,
    variables: {
      email: user?.email,
      site: siteAlias?.toUpperCase(),
    },
  });
  const userWithProducts = data?.userByMail;

  React.useEffect(() => {
    if (userWithProducts && userWithProducts.products) {
      const product = getProductInfo(userWithProducts.products);
      setDisplayFOMOToolTip(product.subscriptionExpiresSoon);
    }
  }, [ userWithProducts, ]);

  return isDisplayFOMOToolTip;
}

export default DisplayFomoToolTip;
