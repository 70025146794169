/* eslint-disable react/prop-types */
/* global document localStorage  */
import * as React from 'react';
import { useFela, } from 'react-fela';
import dynamic from 'next/dynamic';
import { useQuery, useMutation, } from '@apollo/react-hooks';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { UPDATE_READING_LIST_MUTATION, GET_READING_LIST_IDS_QUERY, } from '@haaretz/graphql';
import IconReading from '../../Icon/icons/IconReading';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';
import ActionButton from '../ActionButton';
import ClickAreaButton from '../Button';
import getSiteCodeName from '../../../utils/getSiteCodeName';
import { useUser, } from '../../User/UserDispenser';


import ReadingListAlertDialog from './ReadingListAlertDialog';
import ReadingListCappedDialog from './ReadingListCappedDialog';
import { ADD_WITH_FORCE_KEY, } from './consts';
import useWebViewChecker from '../../../hooks/useWebViewChecker';

const SpaceshipAndAstronaut = dynamic(import('../../illustrations/SpaceshipAndAstronaut'));
const SubmarineAndDiver = dynamic(import('../../illustrations/SubmarineAndDiver'));
const Noop = () => null;

const ReadingListDisabledDialog = dynamic(import('./FeatureDisabledDialog'));
const ReadingListDisabledDialogEng = dynamic(import('./FeatureDisabledDialogEng'));

const illustrations = {
  htz: SpaceshipAndAstronaut,
  tm: SubmarineAndDiver,
  hdc: Noop,
};

const readingListDisabledDialogs = {
  htz: ReadingListDisabledDialog,
  tm: ReadingListDisabledDialog,
  hdc: ReadingListDisabledDialogEng,
};


const DisabledFeatureIllustration = illustrations[getSiteCodeName()];
const FeatureDisabledDialog = readingListDisabledDialogs[getSiteCodeName()];

// const SSOCookie = getSSOCookie();

function SaveToReadingList({
  buttonStyles,
  textMiscStyles = {},
  iconStyles,
  clickAreaSize,
  size,
  articleId,
  userType,
  biImpression,
  biAction,
  biActionMapper,
  inMobileMenu,
  isSpotList,
  spotArticleId,
  // destructuring next couple of props to avoid passing them down to the DOM element
  // (the generic ActionButtons always passes down these props)
  elementName,
  elementUrl,
  ...props
}) {
  const [ readingListState, setReadingListState, ] = React.useState({ exist: false, full: false, total: 0, });
  const [ dialogMode, setDialogMode, ] = React.useState(null);
  const [ isBusy, setIsBusy, ] = React.useState(false);
  const { css, theme, } = useFela();
  const isWebView = useWebViewChecker();
  const { user, } = useUser();

  // if spotArticleId is passed, it means we are in a spot list
  // and we want to use the spotArticleId as the articleId
  const currentArticleId = spotArticleId || articleId;

  useQuery(GET_READING_LIST_IDS_QUERY, {
    skip: isWebView,
    ssr: false,
    variables: {
      input: {
        articleId: currentArticleId,
      },
    },
    onCompleted: data => {
      setReadingListState({
        exist: data?.readingListIds?.exist,
        full: data?.readingListIds?.full,
        total: data?.readingListIds?.total,
      });
    },
  });

  const [ updateReadingList, ] = useMutation(UPDATE_READING_LIST_MUTATION);

  const update = ({ operation, useForce = false, alertType = null, } = {}) => {
    let withForce = useForce;
    if (!withForce && operation === 'addArticle') {
      try {
        withForce = localStorage.getItem(ADD_WITH_FORCE_KEY) === 'true';
      }
      catch { /* */ }
    }

    return updateReadingList({
      variables: {
        input: {
          action: operation, // addArticle/removeArticle/createAlert/removeAlert
          articleId: currentArticleId,
          alertType, // week/day
        },
      },
    });
  };

  const addSubtract = ({ useForce = false, operation, isSaved = false, } = {}) => {
    if (!user?.email || isBusy) return;
    setIsBusy(true);
    setDialogMode(null);

    update({ useForce, operation, })
      .then(({ data: { updateReadingList: { status, }, }, }) => {
        if (readingListState.full && !isSaved) {
          setDialogMode('capped');
        }
        else if (status === 'ok') {
          setReadingListState({ exist: operation === 'addArticle', });
          biAction({
            actionCode: biActionMapper.get(
              `${operation === 'addArticle' ? 'save' : 'remove'}_article`
            ),
            featureType: 'Content',
            feature: isSpotList ? 'Reading list in List'
              : inMobileMenu ? 'Article page bottom navigation' : 'Article page button',
            campaign_name: 'Reading List Button',
            ...isSpotList ? { campaignName: 'Spot', campaignDetails: spotArticleId, } : {},
          });
          if (operation === 'addArticle') {
            setDialogMode('alert');
          }
        }
      })
      .finally(_ => {
        setIsBusy(false);
      });
  };

  const setAlert = alertType => {
    setIsBusy(true);
    return update({ operation: 'createAlert', alertType, })
      .finally(() => {
        setIsBusy(false);
        setDialogMode(null);
        biAction({
          actionCode: 173,
          featureType: 'Content',
          feature: 'Reading List  Alert',
          campaignName: alertType === 'day' ? 'one day' : alertType,
          ...isSpotList ? { campaignName: 'Spot', campaignDetails: spotArticleId, } : {},
        });
      });
  };

  const operation = readingListState.exist ? 'removeArticle' : 'addArticle';
  const dialogAnchorRef = React.useRef(null);
  const parentRef = React.useRef(null);

  const buttonMiscStyles = buttonStyles && (buttonStyles.func || buttonStyles.global)
    ? {
      ...(buttonStyles.func ? buttonStyles.func(readingListState.exist) : {}),
      ...(buttonStyles.global || {}),
    }
    : buttonStyles;

  return (
    /**
     * TODO: Giving the button styles to both wrapper and button is a shaky solution
     * for the save action dialog desktop element - we should try to find a better one
    */
    <div
      ref={parentRef}
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        extend: [
          ...(buttonMiscStyles ? parseStyleProps(buttonMiscStyles, theme.mq, theme.type) : []),
          { position: 'relative', },
        ],
      })}
    >
      <ClickAreaButton
        {...props}
        data-test="saveArticleButton"
        size={clickAreaSize}
        miscStyles={{
          ...buttonMiscStyles,
          direction: 'rtl',
        }}
        onClick={() => {
          if (userType === 'paying') {
            addSubtract({ operation, isSaved: readingListState.exist, });
          }
          else {
            setDialogMode('disabled');
            biAction({
              actionCode: 88,
              campaign_name: 'Reading List Button',
              featureType: 'Content',
              feature: isSpotList ? 'Reading list in List'
                : inMobileMenu ? 'Article page bottom navigation' : 'Article page button',
              ...isSpotList ? { campaignName: 'Spot', campaignDetails: spotArticleId, } : {},
            });
            biImpression({
              feature: 'Reading',
              featureType: 'Content',
              campaign_name: 'לרכישת מנוי',
            });
          }
        }}
      >
        <span
          data-test="saveArticleButtonText"
          aria-hidden
          className={css({
            color: theme.color('neutral', '-3'),
            paddingBlockStart: '0.3rem',
            display: 'none',
            extend: [ theme.mq({ from: 'm', }, { display: 'inline', }), textMiscStyles, ],
          })}
        >
          {theme.readingListActionsI18n[operation]}
        </span>
        <span
          ref={dialogAnchorRef}
          className={css({ display: 'flex', alignItems: 'center', })}
        >
          <IconReading
            size={size}
            miscStyles={iconStyles}
            isFilled={readingListState.exist}
          />
        </span>
        <VisuallyHidden>{theme.readingListActionsI18n.a11y[operation]}</VisuallyHidden>
      </ClickAreaButton>

      <ReadingListAlertDialog
        inMobileMenu={inMobileMenu}
        isVisible={dialogMode === 'alert'}
        anchorRef={dialogAnchorRef}
        parentRef={parentRef}
        setAlert={setAlert}
        cancel={() => setDialogMode(null)}
      />

      <ReadingListCappedDialog
        count={readingListState.total}
        isVisible={dialogMode === 'capped'}
        approve={() => {
          addSubtract({ useForce: true, operation: 'addArticle', isSaved: true, });
          setDialogMode(null);
        }}
        cancel={() => setDialogMode(null)}
      />
      {
        userType !== 'paying'
          ? (
            <FeatureDisabledDialog
              isVisible={dialogMode === 'disabled'}
              onClose={() => setDialogMode(null)}
              illustration={DisabledFeatureIllustration}
              title={theme.readingListDisabled.title}
              subtitle={theme.readingListDisabled.subTitle}
              actionText={theme.readingListDisabled.button}
              actionTarget={theme.readingListDisabled.href}
              illustrationMiscStyles={{
                right: '2rem',
              }}
              contentMiscStyles={{
                top: [
                  { until: 's', value: '33rem', },
                  { from: 's', until: 'xl', value: '55rem', },
                  { from: 'xl', value: '47rem', },
                ],
              }}
            />
          )
          : null
      }

    </div>
  );
}

export default function ActionSave(props) {
  return (
    <ActionButton
      render={({ articleId, userType, biAction, biImpression, biActionMapper, }) => (
        <SaveToReadingList
          {...props}
          articleId={articleId}
          userType={userType}
          biAction={biAction}
          biImpression={biImpression}
          biActionMapper={biActionMapper}
        />
      )}
    />
  );
}
