// @flow
import * as React from 'react';
import { getHostname, } from '@haaretz/app-utils';
import EventTracker from '../../utils/EventTracker';
import Query from '../ApolloBoundary/Query';
import type { ActionButtonProps, } from './types';
import GET_PAGE_DATA from './queries/getPageData.graphql';
import { useUser, } from '../User/UserDispenser';

export default function ActionButton({ render, }: ActionButtonProps) {
  const { user, } = useUser();
  const userType = user?.type;

  return (
    <Query query={GET_PAGE_DATA}>
      {({ loading, error, data, }) => {
        if (loading) return null;
        if (error) console.log(error);

        const {
          platform,
          hostname,
          zenMode,
          articleId,
        } = data;
        const host = getHostname(hostname);
        return (
          <EventTracker>
            {({ biAction, biImpression, biActionMapper, }) => render({
              platform,
              biAction,
              biImpression,
              biActionMapper,
              host,
              hostname,
              articleId,
              userType,
              zenMode,
            })
            }
          </EventTracker>
        );
      }}
    </Query>
  );
}
