// @flow
/* global window navigator */

import * as React from 'react';
import { useFela, } from 'react-fela';
import gql from 'graphql-tag';
import { useQuery, } from 'react-apollo';
import { IconWhatsapp, IconLink, } from '@haaretz/htz-components';
import { useEventTracker, } from '../../../../utils/EventTracker';
import useOneTime from '../../../../hooks/useOneTime';
import ActionDialog from '../../ActionDialog';
import Button from '../../Button';
import LinkButton from '../../../Button/Button';
import type { ShareComponentProps, ShareData, } from '../useShare';
import { useDisplayFomoToolTip, } from '../../../FomoToolTip/DisplayFomoToolTip';
import { useUser, } from '../../../User/UserDispenser';

type Props = ShareComponentProps & {
  shareCount: number,
  shareLimit: number,
};

const GET_PALTFORM_QUERY = gql`
  query GetPlatform {
    platform @client
  }
`;

// =========== Styles =================
function contentWrapperStyle({ theme, }) {
  return {
    ...theme.type(-1),
    textAlign: 'center',
    extends: [
      theme.mq({ from: 's', }, {
        textAlign: 'start',
        ...theme.type(-2),
      }),
    ],
  };
}

function headerStyle({ theme, }) {
  return {
    fontWeight: 700,
    marginBottom: '1rem',
  };
}

function shareSummaryStyle({ theme, }) {
  return {
    color: theme.color('primary'),
    fontWeight: 700,
  };
}

function buttonsWrapperStyle({ theme, }) {
  return {
    marginTop: '1rem',
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    extend: [
      theme.mq({ from: 's', }, {
        alignItems: 'flex-start',
        borderTop: `1px solid ${theme.color('neutral', '-5')}`,
      }),
    ],
  };
}

function shareButtonStyle({ theme, }) {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  };
}

// =========== Utitlities ==============
function copyToClipbord(sharedData: ShareData) {
  const msg = sharedData?.url || '';
  navigator.clipboard.writeText(msg);
}

function shareToWhatsapp(sharedData: ShareData, platform: 'desktop' | 'mobile' = 'desktop') {
  const articleUrl = new URL(sharedData?.url);
  articleUrl.searchParams.append('utm_source', 'Web_Share');
  articleUrl.searchParams.append('utm_medium', 'Whatsapp');
  articleUrl.searchParams.append('utm_campaign', 'Share');

  const msg = encodeURIComponent(`${sharedData?.title}\n${sharedData?.url}`);
  const whatsappUrl = `${platform === 'mobile'
    ? 'whatsapp://'
    : 'https://web.whatsapp.com/'
  }send?text=${msg}`;

  if (platform === 'mobile') {
    window.location.href = whatsappUrl;
  }
  else {
    window.open(
      whatsappUrl,
      'popup',
      'width=635,height=800,scrollbars=no,resizable=no,toolbar=no,directories=no,location=no,menubar=no,status=no'
    );
  }
}

GiftShare.defaultProps = {
  GiftShare: null,
};

export default function GiftShare({ sharedData, shareCount, shareLimit, anchorRef, parentRef, onClose, onShow, cancelShare, rejectShare, approveShare, }: Props) {
  const { theme, css, } = useFela();
  const { data: { platform, }, } = useQuery(GET_PALTFORM_QUERY);
  const { biAction, biImpression, } = useEventTracker();
  const displayFomoToolTip = useDisplayFomoToolTip();
  const { user, } = useUser();
  const userType = user?.type || 'anonymous';
  const isPaying = userType === 'paying';

  useOneTime(!!sharedData?.url, () => {
    if (isPaying) {
      biImpression({
        feature: 'Web gift popup - subs',
        featureType: 'Content',
        campaignName: 'Gift article',
      });
      if (displayFomoToolTip) {
        biImpression({
          feature: 'Web gift popup - subs',
          featureType: 'Marketing',
          campaignName: 'fomo',
          campaignDetails: 'Gift article',
        });
      }
    }
    else {
      biImpression({
        feature: 'Web gift popup - non subs',
        featureType: 'Marketing',
        campaignName: 'Gift article',
        campaignDetails: 'Subscribe to send gift article',
      });
    }
  });


  if (!sharedData?.url) {
    return null;
  }


  return (
    <ActionDialog
      isVisible
      anchorRef={anchorRef}
      parentRef={parentRef}
      displayFomoToolTip={displayFomoToolTip}
      onClose={() => {
        cancelShare();
        onClose && onClose();
      }}
      onShow={onShow}
    >

      <div className={css(contentWrapperStyle)}>
        <div className={css({
          paddingInlineStart: displayFomoToolTip ? '3rem' : '0',
          paddingInlineEnd: displayFomoToolTip ? '3rem' : '0',
        })}
        >
          <header className={css(headerStyle)}>{theme.giftShareI18n.shareHeader}</header>
          <p>
            {theme.giftShareI18n.shareSummary}
          </p>

          <div className={css(buttonsWrapperStyle)}>
            <Button
              className={css(shareButtonStyle)}
              onClick={() => {
                copyToClipbord(sharedData);
                approveShare();
                onClose && onClose();
                biAction({
                  actionCode: 169,
                  feature: 'Web gift popup - subs',
                  featureType: 'Content',
                  campaignName: 'Gift article',
                  campaignDetails: 'Gift sent from subscriber - copy link',
                });
              }}
            >
              <IconLink size={3.5} color={[ 'giftTooltip', 'icon', ]} />
              <span>{theme.giftShareI18n.shareCopyToClipboard}</span>
            </Button>
            <Button
              className={css(shareButtonStyle)}
              onClick={() => {
                shareToWhatsapp(sharedData, platform);
                approveShare();
                onClose && onClose();
                biAction({
                  actionCode: 169,
                  feature: 'Web gift popup - subs',
                  featureType: 'Content',
                  campaignName: 'Gift article',
                  campaignDetails: 'Gift sent from subscriber - whatsapp',
                });
              }}
            >
              <IconWhatsapp size={4.5} color={[ 'giftTooltip', 'icon', ]} />
              <span>{theme.giftShareI18n.shareWhatsapp}</span>
            </Button>
          </div>
        </div>
        {displayFomoToolTip
          ? (
            <div className={css({
              paddingTop: '2rem',
              marginTop: '2rem',
              textAlign: 'center',
              backgroundColor: theme.color('tldr', 'bg'),
              ...theme.type(-1),
            })}
            >
              <div className={css({ fontWeight: '700', marginBottom: '2rem', })}>{theme.fomoToolTipI18n.text.giftArticle}</div>
              <LinkButton
                onClick={() => biAction({
                  actionCode: 3,
                  feature: 'Web gift popup - subs',
                  featureType: 'Marketing',
                  campaignName: 'fomo',
                  campaignDetails: 'Gift article',
                })
              }
                href={theme.fomoToolTipI18n.href.giftArticle}
                variant="salesOpaque"
                fontSize={-1}
                miscStyles={{
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  paddingInlineStart: '3rem',
                  paddingInlineEnd: '3rem',
                  marginBottom: '2.5rem',
                  borderRadius: '50px',
                }}
              >
                {theme.fomoToolTipI18n.text.button}
              </LinkButton>
            </div>
          ) : null }
      </div>
    </ActionDialog>
  );
}
