import config from 'config';

const domain2Code = Object.freeze({
  'haaretz.co.il': 'htz',
  'themarker.com': 'tm',
  'haaretz.com': 'hdc',
});

export default function getSiteCodeName() {
  return domain2Code[config.get('domain')];
}
