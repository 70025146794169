// @flow

import { useQuery, } from 'react-apollo';
import { ARTICLE_DATA_QUERY, } from '@haaretz/graphql';
import { useRouter, } from 'next/router';
import useArticleId from './useArticleId';
import useArticleType from './useArticleType';
import usePrint from './usePrint';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

export default function useArticleData() {
  const articleId = useArticleId();
  const articleType = useArticleType();
  const router = useRouter();
  const { isPrint, } = usePrint();

  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, loading, error, } = useQuery(ARTICLE_DATA_QUERY, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,
  });

  if (loading) return null;
  if (error) {
    console.error('Article error', error);
    return null;
  }

  if (!data) return null;

  const articleData = data.Page?.slots?.article;

  if (articleData && articleType === 'gameArticle') {
    articleData.showIds = articleData.showIds || router.query.showids;
  }

  if (isPrint && articleData) {
    articleData.openingElement = null;
  }

  return articleData;
}
