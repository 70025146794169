// @flow
/* global fetch document */
import config from 'config';

type ShareUrlRequestParams = {
  articleId: string,
}

/**
 * Retrives an HTZ-article URL with access token that can be shared, and gift usage statistics.
 * @param {ShareUrlRequestParams} shareUrlRequestParams
 */
export default async function generateGiftData(shareUrlRequestParams: ShareUrlRequestParams) {
  const serviceBase = config.get('service.personalization');
  const { articleId, } = shareUrlRequestParams;

  const params = new URLSearchParams({ articleId, });
  let shareData = null;

  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const res = await fetch(`${serviceBase}/gifts/generate-token?${params}`, options).then(resp => resp.json());

  if (res?.status === 'ok') {
    const url = new URL(document.URL);
    url.search = '';
    url.searchParams.append('gift', res?.token);

    shareData = { url: url.toString(), count: res.count, limit: res.limit, };
  }
  else if (res?.status === 'exceeded') {
    throw new Error(`User exceeded the montly ${res?.limit} credits`, { cause: { type: 'GiftQuataExceededError', count: res.count, limit: res.limit, }, });
  }
  else if (res?.error) {
    throw new Error(res.error, { cause: { type: 'GiftServiceError', }, });
  }
  else {
    throw new Error('Gifts cannot be sent at this time. Please try again later', { cause: { type: 'GiftServiceError', }, });
  }

  return shareData;
}
