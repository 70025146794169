// @flow
/* global window localStorage sessionStorage document navigator */

import * as React from 'react';
import { useFela, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import { useUser, } from '../../../User/UserDispenser';
import IconGift from '../../../Icon/icons/IconGift';
import ActionButton from '../../Button';
import Button from '../../../Button/Button';
import useShare from '../useShare';
import GiftShare from './GiftShare';
import generateGiftData from './generateGiftData';
import { useEventTracker, } from '../../../../utils/EventTracker';
import ActionDialog from '../../ActionDialog';
import BubbleTooltip from '../../../Tooltip/BubbleTooltip';
import useAppURL from '../../../../hooks/useAppURL';
import useOneTime from '../../../../hooks/useOneTime';
import useArticleId from '../../../../hooks/Page/useArticleId';

type PropsType = {
  elementName: string,
  elementUrl: string,
  size: number,
  buttonStyles?: StyleProps,
};

type ErrorPropsType = {
  error: Error,
  anchorRef: React.Ref<React.ElementType>,
  parentRef: React.Ref<React.ElementType>,
  isVisible: Boolean,
  isPaying: Boolean,
  onClose: () => undefined,
}

function isMobileSize(theme) {
  const mqStr = theme.getMqString({ until: 's', }, true);
  const mq = window.matchMedia(mqStr);

  return mq.matches;
}

// =============== Styles ================
function actionWrapperStyle({ theme, }) {
  return {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    extend: [
      theme.mq({ from: 's', }, {
        flexDirection: 'row',
      }),
    ],
  };
}

const errorTypes = new Map([
  [ 'GiftQuataExceededError', GiftQuataExceededError, ],
  [ 'GiftServiceError', GiftServiceError, ],
]);

const TooltipTypes = {
  anonymous: 'anonymous',
  introduction: 'introduction',
  error: 'error',
};

GiftButton.defaultProps = {
  buttonStyles: null,
};

export default function GiftButton({ size, elementName, elementUrl, buttonStyles, }: PropsType) {
  const { theme, css, } = useFela({ buttonStyles, });
  const articleId = useArticleId();

  const user = useUser();
  const { biAction, } = useEventTracker();
  const [ share, ShareComponent, ] = useShare(GiftShare);
  const [ isButtonDisabled, setIsButtonDisabled, ] = React.useState(false);

  const [ error, setError, ] = React.useState(null);
  const [ visibleTooltip, setVisibleTooltip, ] = React.useState();

  const anchorRef = React.useRef();
  const parentRef = React.useRef();

  const userType = user?.user?.type || 'anonymous';

  const onClickPayingUser = () => {
    const title = `${theme.giftShareI18n.shareKicker}\n${elementName}`;

    generateGiftData({
      articleId,
    })
      .then(shareData => share(
        { title, url: shareData.url, },
        { shareCount: shareData.count,
          shareLimit: shareData.limit,
          parentRef,
          anchorRef,
          onClose: () => setIsButtonDisabled(false),
          onShow: () => setVisibleTooltip(null), }))
      .catch(err => {
        const errorType = err?.cause?.type;
        if (errorTypes.has(errorType)) {
          setError(err);
          setVisibleTooltip(TooltipTypes.error);
        }

        setIsButtonDisabled(false);
      });

    setIsButtonDisabled(true); // prevent from user from double clicking the button

    biAction({
      feature: 'Article Page - Gift Icon',
      featureType: 'Content',
      campaignName: 'Gift article',
      actionCode: '169',
    });
  };

  const onClickNonPayingUser = () => {
    setVisibleTooltip(prevState => (prevState === TooltipTypes.anonymous ? null : TooltipTypes.anonymous));

    biAction({
      feature: 'Article Page - Gift Icon',
      featureType: 'Content',
      campaignName: 'Gift article',
      actionCode: '169',
    });
  };

  const onClick = userType === 'paying' ? onClickPayingUser : onClickNonPayingUser;

  return (
    <div className={css(actionWrapperStyle)} ref={parentRef}>
      <ShareComponent />
      { visibleTooltip === 'error'
        ? renderError({ anchorRef, parentRef, error, onClose: () => setError({ isError: false, error: null, }), isPaying: userType === 'paying', },)
        : null }
      { visibleTooltip === 'anonymous'
        ? (<AnonymousTooltip anchorRef={anchorRef} onHide={() => setVisibleTooltip(null)} />)
        : null }
      { visibleTooltip === 'introduction'
        ? <GiftItroductionTooltip anchorRef={anchorRef} onHide={() => setVisibleTooltip(null)} />
        : null
       }
      <ActionButton onClick={onClick} disabled={isButtonDisabled} miscStyles={buttonStyles} title={theme.shareBarI18n.giftToolTip}>
        <div ref={anchorRef}>
          <IconGift size={size} />
        </div>
      </ActionButton>
    </div>
  );
}

// ========= Error Components ===========
function renderError({ anchorRef, parentRef, onClose, error, isPaying, }: ErrorPropsType) {
  const errorType = error?.cause?.type;
  const ErrorComponent = errorTypes.get(errorType);

  return ErrorComponent
    ? <ErrorComponent {...({ anchorRef, parentRef, onClose, error, isVisible: true, isPaying, })} />
    : null;
}

function GiftQuataExceededError({ anchorRef, parentRef, isVisible, onClose, error, isPaying, }: ErrorPropsType) {
  const { theme, css, } = useFela();
  const { biImpression, } = useEventTracker();

  useOneTime(isPaying, () => {
    biImpression({
      feature: 'Web gift popup - subs',
      featureType: 'Content',
      campaignName: 'Gift article',
      campaignDetails: 'subscriber exceeded max gifts',
    });
  });

  return (
    <ActionDialog anchorRef={anchorRef} parentRef={parentRef} isVisible={isVisible} onClose={onClose}>
      <p className={css({
        textAlign: 'center',
        extend: [ theme.mq({ from: 's', }, { textAlign: 'unset', }), ], })}
      >
        {theme.giftShareI18n.shareError.GiftQuataExceededError[0]}
        <span className={css({ color: theme.color('primary'), fontWeight: 700, })}>
          {error?.cause?.count}
          /
          {error?.cause?.limit}
        </span>
        {theme.giftShareI18n.shareError.GiftQuataExceededError[1]}
        <br />
        {theme.giftShareI18n.shareError.GiftQuataExceededError[2]}
        {error?.cause?.limit}
        {theme.giftShareI18n.shareError.GiftQuataExceededError[3]}
      </p>
    </ActionDialog>
  );
}

function GiftServiceError({ anchorRef, parentRef, isVisible, onClose, error, isPaying, }: ErrorPropsType) {
  const { theme, } = useFela();

  return (
    <ActionDialog anchorRef={anchorRef} parentRef={parentRef} isVisible={isVisible} onClose={onClose}>
      <p>
        {theme.giftShareI18n.shareError.GiftServiceError}
      </p>
    </ActionDialog>
  );
}

// ========== Anonymous Tooltip ==============
type AnonymousTooltipProps = {
  anchorRef: React.Ref,
  onHide?: (() => undefined),
};

AnonymousTooltip.defaultProps = {
  onHide: null,
};

function AnonymousTooltip({ anchorRef, onHide, }: AnonymousTooltipProps) {
  const { theme, css, } = useFela();
  const { biAction, biImpression, } = useEventTracker();
  let promotionsUrl = useAppURL('promotion');
  promotionsUrl += `${(promotionsUrl.indexOf('?') === -1) ? '?' : '&'}htm_source=site&htm_medium=button&htm_campaign=gift&htm_content=subscribe`;
  const mobile = isMobileSize(theme);
  const direction = mobile ? 'up' : 'down';
  const offset = mobile ? -16 : 0;

  React.useEffect(() => {
    biImpression({
      featureType: 'Marketing',
      feature: 'Web gift popup - non subs',
      campaignName: 'Gift article',
      campaignDetails: 'subscribe to send gift article',
    });
  }, [ biImpression, ]);

  return (
    <BubbleTooltip
      frameMiscStyles={{
        backgroundColor: theme.color('giftTooltip', 'bg'),
        color: theme.color('giftTooltip', 'border'),
      }}
      direction={direction}
      offset={offset}
      target={anchorRef}
      isVisible
      onHide={onHide}
    >
      <div className={css({ display: 'flex', alignItems: 'center', padding: '0 1rem', gap: '2rem', })}>
        <span className={css({
          fontWeight: 700,
          width: '26rem',
          extend: [
            theme.type(-1),
            theme.type(-2, { fromBp: 's', }),
          ],
        })}
        >
          {theme.giftShareI18n.promotionText}
        </span>
        <Button
          href={promotionsUrl}
          onClick={() => biAction({
            actionCode: 3,
            featureType: 'Marketing',
            feature: 'Web gift popup - non subs',
            campaignName: 'Gift article',
            campaignDetails: 'subscribe to send gift article',
          })}
          variant="salesOpaque"
          miscStyles={{
            type: [ { until: 's', value: -1, }, { from: 's', value: -2, }, ],
          }}
          boxModel={{ hp: 1, vp: 0, }}
        >
          {theme.giftShareI18n.promotionAction}
        </Button>
      </div>
    </BubbleTooltip>
  );
}

type GiftItroductionTooltipProps = {
  anchorRef: React.Ref<React.ElementType>,
  onHide: () => void,
};

function GiftItroductionTooltip({ anchorRef, onHide, }: GiftItroductionTooltipProps) {
  const { theme, css, } = useFela();
  const mobile = isMobileSize(theme);
  const direction = mobile ? 'up' : 'down';
  const offset = mobile ? -16 : 0;

  return (
    <BubbleTooltip
      frameMiscStyles={{
        backgroundColor: theme.color('giftTooltip', 'bg'),
        color: theme.color('giftTooltip', 'border'),
      }}
      onHide={onHide}
      direction={direction}
      offset={offset}
      target={anchorRef}
      isVisible
    >
      <div className={css({ display: 'flex', alignItems: 'center', justifyContent: 'center', })}>
        <span className={css({
          width: '35rem',
          textAlign: 'center',
          extend: [
            theme.type(-1),
            theme.type(-2, { fromBp: 's', }),
            theme.mq({ from: 's', }, { width: '42rem', }),
          ],
        })}
        >
          <strong>{theme.giftShareI18n.introduction[0]}</strong>
          {` ${theme.giftShareI18n.introduction[1]}`}
        </span>
      </div>
    </BubbleTooltip>
  );
}
